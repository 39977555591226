function setupLinkstrips() {
	document.querySelectorAll<HTMLDivElement>(".sv--linkstrip").forEach(linkstrip => {
		let prev = linkstrip.querySelector<HTMLButtonElement>(".sv--linkstrip-prev");
		let next = linkstrip.querySelector<HTMLButtonElement>(".sv--linkstrip-next");
		let pane = linkstrip.querySelector<HTMLDivElement>(".sv--linkstrip-pane");
		var active = linkstrip.querySelector<HTMLAnchorElement>(".sv--linkstrip-link");
		let scrollToTarget = function (target: HTMLAnchorElement) {
			if (target) {
				pane.scrollTo({
					behavior: "smooth",
					left: target.offsetLeft,
				});
				active.classList.remove("sv--active");
				active = target;
				active.classList.add("sv--active");
				prev.disabled = target.previousElementSibling ? false : true;
				next.disabled = target.nextElementSibling ? false : true;
			}
		}
		prev.addEventListener("click", evt => scrollToTarget(active.previousElementSibling as HTMLAnchorElement));
		next.addEventListener("click", evt => scrollToTarget(active.nextElementSibling as HTMLAnchorElement));
	});
}
function setupToggles() {
	document.querySelectorAll<HTMLButtonElement>("[data-toggle]").forEach(toggle => {
		toggle.addEventListener("click", evt => {
			var text = toggle.textContent;
			toggle.textContent = toggle.dataset.toggleText;
			toggle.dataset.toggleText = text;
			var target = document.getElementById(toggle.dataset.toggleTarget);
			if (target) {
				target.classList.toggle("sv--active");
			}
		});
	});
}
document.addEventListener("DOMContentLoaded", evt => {
	setupLinkstrips();
	setupToggles();
});
